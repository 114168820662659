<template>
  <div class="mt-3">
    <b-row
      class="justify-content-center h-100 mx-0"
    >
      <!-- <b-col md="12" align-self="center"> -->
      <!-- <b-card-group colums> -->
      <!-- <b-row 
            class="justify-content-center"> -->
      <b-col align-self="center" class="p-0">
        <ul class="list-unstyled">
          <div v-for="(list, index) in listBoard" :key="index">
            <b-media
              v-if="index === 0"
              no-body
              tag="li"
              class="px-2 mb-2"
              style="border-radius:15px;background:#C82625;position:relative;box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);"
            >
              <b-media-aside vertical-align="center" class="mr-1">
                <b-img
                  :src="require('@/assets/profile.png')"
                  alt="Media Aside"
                  class="img-ldrboard"
                ></b-img>
              </b-media-aside>
              <b-media-body vertical-align="center">
                <h4 class="mb-1 pt-3" style="text-align:left;color:white;">
                  {{ list.msisdn }}
                </h4>
                <b-img
                  :src="require('@/assets/cup-1.png')"
                  alt="Media Aside"
                  style="width:3rem; height:3rem;position:absolute;right:0.5rem;top:0.6rem;"
                ></b-img>
              </b-media-body>
            </b-media>

            <b-media
              v-if="index === 1"
              no-body
              tag="li"
              class="px-2 mb-2"
              style="border-radius:15px;background-color:#E46D6D;position:relative;box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);"
            >
              <b-media-aside vertical-align="center" class="mr-1">
                <b-img
                  :src="require('@/assets/profile.png')"
                  alt="Media Aside"
                  class="img-ldrboard"
                ></b-img>
              </b-media-aside>
              <b-media-body vertical-align="center">
                <h4 class="mb-1 pt-3" style="text-align:left;color:white;">
                  {{ list.msisdn }}
                </h4>
                <b-img
                  :src="require('@/assets/cup-2.png')"
                  alt="Media Aside"
                  style="width:3rem; height:3rem;position:absolute;right:0.5rem;top:0.6rem;"
                ></b-img>
              </b-media-body>
            </b-media>

            <b-media
             v-if="(index !== 0) && (index !== 1)"
              no-body
              tag="li"
              class="px-2 mb-2"
              style="border-radius:15px;background-color:#FFFFFF;position:relative;box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);"
            >
              <b-media-aside vertical-align="center" class="mr-1">
                <b-img
                  :src="require('@/assets/profile.png')"
                  alt="Media Aside"
                  class="img-ldrboard"
                ></b-img>
              </b-media-aside>
              <b-media-body vertical-align="center">
                <h4 class="mb-1 pt-3" style="text-align:left;color:black;">
                  {{ list.msisdn }}
                </h4>
                <b-img
                  :src="require(`@/assets/list-${index+1}.png`)"
                  alt="Media Aside"
                  style="width:2.3rem; height:2.3rem;position:absolute;right:0.5rem;top:0.6rem;padding-top: 0.5rem;"
                ></b-img>
              </b-media-body>
            </b-media>
          </div>
        </ul>
      </b-col>
    </b-row>
  </div>
</template>
<script>
export default {
  name: "Listboard",
  data() {
    return {
        // lists: this.listBoard,
    //   lists: [
    //     {
    //       msisdn: "62812345xxxx",
    //       point: "10000"
    //     },
    //     {
    //       msisdn: "62812346xxxx",
    //       point: "9000"
    //     },
    //     {
    //       msisdn: "62812347xxxx",
    //       point: "8000"
    //     },
    //     {
    //       msisdn: "62812348xxxx",
    //       point: "7000"
    //     },
    //     {
    //       msisdn: "62812349xxxx",
    //       point: "6000"
    //     },
    //     {
    //       msisdn: "62812340xxxx",
    //       point: "5000"
    //     }
    //   ]
    };
  },
  props: ['listBoard'],
  // mounted() {
  //   console.log('list',this.listBoard);
  // }
};
</script>
<style scoped>
  .img-ldrboard {
    width:4rem;
    height:4rem;
    padding:8px;
  }
</style>