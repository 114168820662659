<template>
    <div class="header-home" :class="{ 'transparent' : !showBg }">
      <!-- <b-row class="mx-0 align-items-center">
        <b-col class="px-0"> </b-col>
        <b-col cols="5" class="px-0">
          <h5 class="font-weight-bold text-white mt-4 pt-1">{{titleHeader}}</h5>
        </b-col>
        <b-col class="px-0">
          <div class="mt-4 mr-3">
            <b-media right-align vertical-align="center" class="ml-0">
              <template #aside>
                <img
            alt="Vue logo"
            src="@/assets/icon-token.png"
            class="mt-1"
            style="width: 2.5rem"
          />
              </template>
              <h4 class="mt-3 mb-1 font-weight-bold text-light text-right">{{(dataProfile !== null) ? dataProfile.life : 0}}</h4>
            </b-media>
          </div>
        </b-col>
      </b-row> -->
      <b-row class="align-items-center h-100">
        <div class="col pr-0">
            <!-- <div class="card card-body">Center</div> -->
        </div>
        <div class="col-5 px-0">
            <div class="bg-transparent border-0 card card-body p-0 text-white">
                    <h5 class="card-title mb-0">{{titleHeader}}</h5>
            </div>
        </div>
        <div class="col pl-0">
            <div v-if="showToken" class="bg-transparent border-0 card card-body p-0 text-white flex-row-reverse">
                <!-- <b-media right-align vertical-align="center" class="ml-0">
              <template #aside> -->
                <img
                    alt="Vue logo"
                    src="@/assets/icon-token.png"
                    class="mr-3 ml-2"
                    style="width: 2.5rem; height: 2.5rem"
                />
                  <h4 class="mt-2 mb-1 font-weight-bold text-light text-right">{{coin}}</h4>
              <!-- </template> -->
              
            <!-- </b-media> -->
            </div>
        </div>
      </b-row>
    </div>
</template>
<script>
import { mapState } from 'vuex'
export default {
    name: 'Header',
    data() {
        return {
            dataProfile: null,
            titleHeader: this.title,
            showToken: this.token,
            showBg: this.background,
            // totalCoin: this.coin
            // totalCoin: this.$store.state.coin,
        }
    },
    props: {
      title: String,
      token: Boolean,
      background: Boolean,
      coin: Number,
    },
    computed: {
      ...mapState([
        'profile',
      ])
    },
    mounted() {
      // console.log(JSON.parse(this.profile))
      // this.dataProfile = JSON.parse(this.profile)
    }
}
</script>
<style scoped>
.header-home {
  height: 75px;
  /* background: linear-gradient(90deg, #ff4401 0%, #ff0077 57.29%); */
  background: #120D50;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 999;
}
.transparent {
  /* background: #D82827 !important; */
  background: linear-gradient(rgba(0,0,0,0.5), rgba(0,0,0,0)) !important;
}
</style>